import 'ant-design-vue/dist/antd.less'
import Brezel from '@kibro/brezel-spa'
import App from './App'

const host = window.location.host
let subdomain = host.split('.')[0]

if (host.split('.').length === 1 || subdomain === 'app') { subdomain = 'KABTeam' }

const brezel = new Brezel((window.config || {}).VUE_APP_API_URL || process.env.VUE_APP_API_URL, subdomain)
const app = brezel.bootstrap(App)

window.app = app

export default app.$mount('#app')
