<template>
  <BrezelApp>
    <template slot="logo">
      <img
        src="/assets/logo.svg"
        style="width: 100%; max-height: 100px; padding:20px;"
        alt="Logo"
        @load="addMouseFlow"
      >
    </template>
    <template slot="loginLogo">
      <img
        src="/assets/login-logo.svg"
        :style="{ maxWidth: '100%', margin: '0px auto 26px auto', display: 'block' }"
        alt="logo"
      >
    </template>
  </BrezelApp>
</template>
<script>
import BrezelApp from '@kibro/brezel-spa/src/components/App'

export default {
  name: 'App',
  components: {
    BrezelApp,
  },
  methods: {
    addMouseFlow () {
      window._mfq = window._mfq || [];
      (function () {
        const mf = document.createElement('script')
        mf.type = 'text/javascript'; mf.defer = true
        mf.src = '//cdn.mouseflow.com/projects/e6ff8227-dcf6-4831-8e1d-531fa8b533bd.js'
        document.getElementsByTagName('head')[0].appendChild(mf)
      })()
    },
  },
}
</script>

<style lang="scss">
</style>
